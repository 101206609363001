<template>
  <div class="collect-content">
    <div class="header">
      <div class="text">
        <h1>COLLECT</h1>
      </div>
    </div>
    <el-card class="box-card">
      <div v-if="productCount">
        <el-pagination
            style="margin-top: 80px; text-align: right; width: 100%"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="queryData.page"
            :page-size="queryData.pageSize"
            :page-sizes="[60,120,180]"
            :total="productCount"
            layout="sizes,total, prev, pager, next,jumper"
        >
        </el-pagination>
        <el-row :gutter="10">
          <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3"
            v-for="items in footmarkList"
            :key="items.id"
            style="position: relative"
          >
            <div class="userProductItem">
              <!--主图预览-->
              <el-image
                  class="proImg"
                :src="items.picture"
                :preview-src-list="[items.picture]"
              >
              </el-image>
              <!--产品名称-->
              <div
                class="proName"
                @click="toProductDetail(items.id)"
                :title="items.name"
              >
                <span> {{ items.name }} </span>
              </div>
              <div class="proBox">
                <!--产品编码-->
                <div class="index">Item # {{ items.code }}</div>
                <!--产品价格-->
                <div class="price">
                  ${{ items.min_price }} - {{ items.max_price }}
                </div>
                <!--产品起订量-->
                <div class="qty">
                  <span>MIN QTY:&nbsp;{{ items.min_quantity }}</span>
                </div>
                <el-button
                  @click="collect(items.id)"
                  :type="items.user_collect == 1 ? 'danger' : ''"
                  icon="el-icon-star-off"
                  size="mini"
                  circle
                  style="position: absolute; right: 10px; bottom: 15px"
                ></el-button>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-pagination
          style="margin-top: 80px; text-align: right; width: 100%"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryData.page"
          :page-size="queryData.pageSize"
          :page-sizes="[60,120,180]"
          :total="productCount"
          layout="sizes,total, prev, pager, next,jumper"
        >
        </el-pagination>
      </div>
      <el-empty v-else description="No product data is currently collected"></el-empty>
<!--      <div v-else>
        <div class="emptyBox">
          <el-image :src="emptyImg"> </el-image>
          <p>No product data is currently collected</p>
        </div>
      </div>-->
    </el-card>
  </div>
</template>

<script>
import { userCollect } from "@/api/collect";
import { collectProduct } from "@/api/product.js";
export default {
  data() {
    return {
      emptyImg: require("@/assets/imgs/collect_empty.png"),
      prodictId: "",
      footmarkList: [],
      productCount: 0,
      queryData: {
        page: 1,
        pageSize: 60,
      },
    };
  },
  mounted() {
    this.getUserCollect();
  },
  methods: {
    getUserCollect() {
      const token = localStorage.getItem("token");
      userCollect({
        token: token,
        pageSize: this.queryData.pageSize,
        page: this.queryData.page,
        price_type: localStorage.getItem("price_type"),
      }).then((res) => {
        this.footmarkList = res.data.list;
        this.productCount = res.data.count;
      });
    },
    collect(id) {
      if (
        localStorage.getItem("token") &&
        localStorage.getItem("token") != ""
      ) {
        collectProduct({ id: id, token: localStorage.getItem("token") }).then(
          (res) => {
            this.$message({
              message: res.msg,
              type: "success",
            });
            this.getUserCollect();
          }
        );
      } else {
        this.$confirm("Login Required", "Tips", {
          confirmButtonText: "OK",
          cancelButtonText: "Canecl",
          type: "warning",
        })
          .then(() => {
            this.$router.push({
              path: "login",
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "Cancel Login",
            });
          });
      }
    },
    handleClose(done) {
      done();
    },
    viewDetail() {},
    toProductDetail(id) {
      this.$router.push({
        path: "/product-detail",
        query: {
          id: id,
        },
      });
    },
    handleSizeChange(val) {
      // //console.log(`每页 ${val} 条`);
      this.queryData.pageSize = val;
      this.getUserCollect();
    },
    //当前页改变时触发 跳转其他页
    handleCurrentChange(val) {
      // //console.log(`当前页: ${val}`);
      this.queryData.page = val;
      this.getUserCollect();
    },
  },
};
</script>


<style lang="scss" scoped>
.emptyBox {
  text-align: center;
  color: #666a6b;
}

.header {
  background: url(../../assets/imgs/home/texture-bw.png) center center repeat;
  height: 113px;
  .text {
    max-width: 1170px;
    margin: 0 auto;
    line-height: 113px;
    font-size: 20px;
  }
  h1 {
    font-weight: bolder;
    line-height: 113px;
    margin-top: 0px;
  }
}
.placard {
  background-color: rgb(250 250 250);
  padding-top: 20px;
  .mainBox {
    // margin-top: 60px !important;
    max-width: 1170px;
    min-height: 600px;
    margin: 0 auto;
    background-color: rgb(255 255 255);
    padding: 20px 40px;
    .items {
      padding: 10px;
      margin: 20px;
      cursor: pointer;
      margin-left: -15px;
      margin-right: -15px;
      background-clip: border-box;
      background-color: #fff;
      border: 1px solid rgba(0, 0, 0, 0.125);
      border-radius: 0.25rem;
      position: relative;
      margin-left: 15px;
      margin-right: 15px;
      // width: 183px;
      margin-bottom: 20px;
    }
    img {
      width: 182px;
      height: 182px;
    }
    .name {
      color: #176f95;
      font-size: 14px;
      margin-left: 10px;
      line-clamp: 1;
      cursor: pointer;
      height: 55px;
      // width: 200px !important; //限制文本宽度
      word-wrap: break-word; //自动换行
      word-break: break-all; //自动换行（兼容字母）
      overflow: hidden; //超出隐藏
      text-overflow: ellipsis; //溢出显示省略号
      display: -webkit-box;
      -webkit-line-clamp: 3; //显示3行
      -webkit-box-orient: vertical;
    }
    .name span {
    }
    .name :hover {
      // background-position-x: left;
      // background-size: 100% 2px;
      color: #275366;
    }
    .index {
      color: #666a6b !important;
      font-size: 11px;
      margin-left: 10px;
      margin-top: 10px;
    }
    .price {
      font-size: 13px;
      color: #666a6b !important;
      margin-left: 10px;
      line-height: 25px;
    }
    .qty {
      font-size: 11px;
      color: #666a6b !important;
      margin-left: 10px;
      line-height: 15px;
    }
  }
}
.userProductItem {
  border: solid 1px #ccc;
  padding: 10px;
  margin: 10px 0px;
  border-radius: 5px;
}
.proName {
  color: #176f95;
  flex: 1 1 auto;
  font-size: 1.5167rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  line-clamp: 1;
  cursor: pointer;
  height: 55px;
  //width: 200px !important; //限制文本宽度
  word-wrap: break-word; //自动换行
  word-break: break-all; //自动换行（兼容字母）
  overflow: hidden; //超出隐藏
  text-overflow: ellipsis; //溢出显示省略号
  display: -webkit-box;
  -webkit-line-clamp: 3; //显示3行
  -webkit-box-orient: vertical;
}
.proName :hover {
  color: #275366;
  text-decoration: underline;
}
.proImg {
  width: 182px;
  height: 182px;

}
.proBox {
  color: #666a6b !important;
  font-size: 11px;
}

.index {
  margin-top: 10px;
}
.price {
  font-size: 13px;
  line-height: 25px;
}
.qty {
  line-height: 15px;
}
</style>
<style scoped>
@media screen and (min-width: 1600px) {
  .el-col-lg-6 {
    width: 25%;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .el-col-lg-6 {
    width: 33.3%;
  }
}
@media screen and (min-width: 800px) and (max-width: 1200px) {
  .el-col-lg-6 {
    width: 50%;
  }
}
</style>
